import './App.css';
import {
  Switch,
  Route
} from 'react-router-dom'
import Index from './pages/index/Index';
import Login from './pages/login/Login';
import Administrators from './pages/administrator/Administrators';
import Feedback from './pages/feedback/Feedback';
import FeedbackDetail from './pages/feedback/FeedbackDetail';
import EditAdministrator from './pages/administrator/EditAdministrator';
import Version from './pages/version/Version';
import ViewVersion from './pages/version/ViewVersion';
import NewVersion from './pages/version/NewVersion';
import Apps from './pages/confighub/Apps';
import AppEdit from './pages/confighub/AppEdit'
import AppConfigs from './pages/confighub/AppConfigs';
import AppConfig from './pages/confighub/AppConfig';

function App() {
  return (
      <Switch>
        <Route path="/">
          <Index>
            <Switch>
            <Route exact path="/feedback"><Feedback/></Route>
            <Route exact path="/feedback/:id"><FeedbackDetail /></Route>
            <Route exact path="/administrators"><Administrators /></Route>
            <Route exact path="/administrator/:id?"><EditAdministrator /></Route>
            <Route exact path='/create/version'><NewVersion></NewVersion></Route>
            <Route exact path='/versions'><Version></Version></Route>
            <Route exact path='/version/:id'><ViewVersion></ViewVersion></Route>
            <Route exact path='/login'><Login></Login></Route>
            <Route exact path='/confighub'><Apps></Apps></Route>
            <Route exact path='/confighub/:appname/:id?'><AppEdit></AppEdit></Route>
            <Route exact path='/confighub/:appname/:appid/configs'><AppConfigs></AppConfigs></Route>
            <Route exact path='/confighub/:appname/:appid/config/:configid?'><AppConfig></AppConfig></Route>
            </Switch>
          </Index>
        </Route>
       
      </Switch>
  );
}

export default App;
