import React, {useState, useEffect} from "react";
import { Form, Input, Button, Breadcrumb, Space } from 'antd';
import { useParams,useHistory} from "react-router";
import requester from "../../utils/requester";

const { TextArea } = Input;

const AppEdit = () => {
    const [form] = Form.useForm();
    const [app, setApp] = useState({})
    // eslint-disable-next-line
    const {id} = useParams()
    const history = useHistory()

    useEffect(() => {
        if (id) {
            requester.get('/app/' + id).then((response) => {  
                if (response.status === 200) {
                    form.setFieldsValue(response.data.data);
                    setApp(response.data.data)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useState(() => {

    }, [])

    const onFinish = (values) => {
        console.log(values)
        if (id) {
            putApp(values)
        } else {
            postApp(values)
        }
        
    }

    const putApp = (values) => {
        requester.put("/app/" + id, values).then((response) => {
            if (response.status === 200) {
                alert("保存成功")
            }
        })
    }

     const postApp = (values) => {
        requester.post("/app/", values).then((response) => {
            if (response.status === 200) {
                alert("保存成功")
                form.resetFields()
            }
        })
     }

     const deleteApp = () => {
        if (window.confirm("你在执行危险的删除操作，成功删除后数据将不可恢复，你确定修改吗？")) {
            requester.delete("/app/" + id).then((response) => {
                if (response.status === 200) {
                    alert("删除成功")
                    history.push("/confighub")
                }
            }).catch((error) => {
                if (error.response) {
                    alert(error.response.data.msg)
                } else {
                    alert("删除失败， 发生未知错误。")
                }
            })
        }
     }

    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>App详情</Breadcrumb.Item>
            </Breadcrumb>
            
            <Form
                form={form}
                layout="vertical"
                initialValues={app}
                onFinish={onFinish}
            >
                <Form.Item label="App 名称" required tooltip="This is a required field" name="name">
                    <Input placeholder="字母、数字、下划线" disabled={!!id}/>
                </Form.Item>
                <Form.Item label="自定义域名" tooltip="自定义的下载域名，需提前配置nginx，带协议前缀，http:// or https://"  name='domain'>
                    <Input placeholder="约定的加密字符串" />
                </Form.Item>
                <Form.Item label="通知链接" tooltip="不为空，会发送POST通知" name='notify_url'>
                    <Input placeholder="不为空，会发送POST通知" />
                </Form.Item>
                <Form.Item label="私钥" tooltip="用于解密，注意保存" name='private_key'>
                    <TextArea row={50} name="private_key" disabled autoSize/>
                </Form.Item>
                <Form.Item label="配置说明" name='remark' tooltip="说明配置文件的用途，以及主要配置项说明。">
                    <TextArea placeholder="说明配置文件的用途，以及主要配置项说明。" autoSize/>
                </Form.Item>
                <Form.Item>
                   <Space size={300}><Button type="primary" htmlType="submit">保存</Button> {id ? <Button type="primary" danger onClick={deleteApp}>删除此App</Button> : <></>}</Space> 
                </Form.Item>
                <p>配置文件使用RSA加密，公钥加密，私钥解密，所以客户端保存私钥。加密数据经过 base64 编码，所以解密前需要先进行 base64 解码。</p>
            </Form>
        </div>
    )
}

export default AppEdit