import React from 'react'
import { Link} from 'react-router-dom'
import { Layout, Menu } from 'antd';
import {
    DesktopOutlined,
    PieChartOutlined,
} from '@ant-design/icons';
import './index.css'

const { Header, Content, Footer, Sider } = Layout;

class Index extends React.Component {
    state = {
        collapsed: false,
    };

    onCollapse = collapsed => {
        console.log(collapsed);
        this.setState({ collapsed });
    };

    render() {
        const { collapsed } = this.state;
        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse}>
                    <div className="logo">Super App</div>
                    <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                        <Menu.Item key="1" icon={<PieChartOutlined />}><Link to='/feedback'>用户反馈</Link></Menu.Item>
                        {/*<Menu.Item key="2" icon={<DesktopOutlined />}><Link to="/versions">版本发布</Link></Menu.Item>*/}
                        <Menu.Item key="3" icon={<DesktopOutlined />}><Link to='/administrators'>管理员</Link></Menu.Item>
                        <Menu.Item key="4" icon={<DesktopOutlined />}><Link to='/confighub'>配置中心</Link></Menu.Item>
                    </Menu>
                </Sider>
                <Layout className="site-layout">
                    <Header className="site-layout-background" style={{ padding: 0 }} />
                    <Content style={{ margin: '0 16px' }}>
                        {this.props.children}
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>Super App ©2023 Power by Ant Design</Footer>
                </Layout>
            </Layout>
        );
    }
}

export default Index;