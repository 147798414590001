import React, { useState, useEffect } from "react";
import { Descriptions, Breadcrumb, Select, Space, Button, Modal, Image, Form, Input, Divider} from 'antd';
import { useParams } from "react-router";
import requester from "../../utils/requester";
import { List, Tag } from "antd";
import {useHistory} from "react-router-dom";

const { TextArea } = Input

const FeedbackDetail = () => {

    const [feedbackDetail, setFeedbackDetail] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    // eslint-disable-next-line
    const [form] = Form.useForm()
    const { id } = useParams()
    const history = useHistory()

    useEffect(() => {
        loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, [])

    const statusChange = (value) => {
        if (window.confirm("确定已经处理了吗？")) {
            setFeedbackStatus(value)
        }
    }

    const loadData = () => {
        requester.get("/feedback/" + id).then((response) => {
            if (response.data.msg === "ok") {
                setFeedbackDetail(response.data.data)
            }
        })
    }

    const setFeedbackStatus = (value) => {
        requester.put("/feedback/" + id + '/status', { status: value }).then((response) => {
            if (response.data.msg === "ok") {
                loadData()
            }
        })
    }

    const showModal = () => {
        form.setFieldsValue({
            to: feedbackDetail.connection,
            subject: feedbackDetail.app_name + ' Feedback Reply',
            content: emailContent(0)
        })
        setIsModalOpen(true);
    };
    const handleOk = () => {
        let values = form.getFieldsValue()
        if (window.confirm("确定发送吗？")) {
            setConfirmLoading(true)
            requester.post('/email', values).then((response) => {
                if (response.status === 200) {
                    alert('已加入邮件发送队列，邮件将自动发送。')
                    setFeedbackStatus(1)
                    setConfirmLoading(false)
                    setIsModalOpen(false);
                }
            })
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const emailContent = (demo) => {
        let content = emailDemos(demo, feedbackDetail.app_name)
        return `尊敬的 ${feedbackDetail.app_name} 用户:
    
    ${content}
    
    非常感谢您使用${feedbackDetail.app_name}。
    ${feedbackDetail.app_name} Team
    `
    }

    const renderImgOrVideo = (url) => {
        let fileType = url.slice(-3)
        console.log(fileType)
        if (fileType === 'mp4') {
            return (
                <List.Item>
                    <video controls width="300">
                        <source src={url} type="video/mp4"></source>
                    </video>
                </List.Item>
            )
        } else {
            return <List.Item><Image src={url} width={300} /></List.Item>
        }
    }

    const emailDemos = (index) => {
        let emailDemo = [
                `我们收到了您提交的反馈,
    
    `,
                `您好，由于我们的App是全球上架的，我们采用苹果App内购的基准价格，单位是美元，这个汇率也是Apple规定的，为了避免汇率的换算及价格体系差异，产品购买页面统一采用使用美元展示，您在选择产品后会弹出支付平台的当地货币价格做进一步确认，望您理解，有问题请随时联系我们！`,
                `如果您购买了VIP无法使用，请先在设置页面注册或者登陆App内部账号，再打开App的订阅页面，点击右上角的“恢复购买”。这样就可以绑定您的VIP权益到你的账号。`,
                `感谢您联系我们，我们会在后面的更新中修复这些bug，我们预计会在最近几次更新中加入这些新功能，请您留意我们的更新。`,
                `您好，非常抱歉给您带来不便，我们的货币单位是美金。由于款项是apple公司代收，我们暂时没有办法给您办理退款。
       人工智能是未来众多行业的发展趋势，使用人工智能提高生产效率。建议你保留使用。`,
                `如果您无法恢复购买，
                
    请您在app设置页面注册或者登录你账号，把账号绑定的手机号或者邮箱发给我们，并且提供购买凭证信息截图。我们收到邮件后会尽快帮您修复问题。`,
                `请您访问 www.chatG.ai 下载最新版的app，请先在旧版上面登录或注册账号，绑定邮箱，然后再在新的app上通过邮箱登录账号即可同步会员。
   
    如果这样操作不行，请您在新的app设置的页面注册或者登陆您的邮箱账号。把绑定的邮箱发给我们，并且提供购买凭证的截图。我们收到邮件后会尽快修复帮您修复问题。`,
    
                `我们收到了您提交的反馈,
    
    您好，这个没有办法，之前的Gai被下架了，往后要经过Pro更新。如果您已经在旧app上购买会员并且登录邮箱，在新app上登录邮箱即可，会员帐户是互通的。`,

            `我们收到了您提交的反馈,
    您好，已经修复完毕。`,
                `我们收到了您提交的反馈,
                
    人工智能服务生成内容需要消耗大量的算力，为了公平以及防止被滥用，我们做了次数上的限制，还请你谅解。
    我们也在努力提高我们的算力，提高我们的服务质量。`,
            `人工智能生成内容存在一定的随机性，当所给提示信息少而要求字数多时容易出现问题。
    建议使用聊天功能，将问题拆分细化，分别提交给人工智能已达到想要字数。`
            ]

        return emailDemo[index]
    }

    const selectEmailContent = (index) => {
        let content = emailContent(index)
        form.setFieldsValue({ content: content })
    }

    const deleteFb = () => {
        if (window.confirm("确定要删除这条反馈吗？删除后无法恢复")) {
            requester.delete('/feedback/' + feedbackDetail.id).then((response) => {
                alert("删除成功")
                history.push('/feedback')
            }).catch((error) => {
                alert('出现错误：'+ error.toString())
            })
        }
    }

    return (
        <div>
            <Modal title="Send Email" open={isModalOpen} width={1000} onCancel={handleCancel} onOk={handleOk} confirmLoading={confirmLoading}>
                <Form
                    form={form}
                >
                    <Form.Item label="Email" name="to" required>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Subject" name="subject" required>
                        <Input />
                </Form.Item>
                <Form.Item label="Demos">
                    <Space wrap>
                        <Button type="primary" onClick={() => {selectEmailContent(0)}} >默认</Button>
                        <Button type="primary" onClick={() => {selectEmailContent(1)}} >货币单位</Button>
                        <Button type="primary" onClick={() => {selectEmailContent(2)}} >VIP恢复购买</Button>
                        <Button type="primary" onClick={() => {selectEmailContent(3)}} >BUG与功能</Button>
                        <Button type="primary" onClick={() => {selectEmailContent(4)}} >退款赠送</Button>
                        <Button type="primary" onClick={() => {selectEmailContent(5)}} >无法恢复购买</Button>
                        <Button type="primary" onClick={() => {selectEmailContent(6)}} >安装新版</Button>
                        <Button type="primary" onClick={() => {selectEmailContent(7)}} >Why换Pro</Button>
                        <Button type="primary" onClick={() => {selectEmailContent(8)}} >VIP修复完毕</Button>
                        <Button type="primary" onClick={() => {selectEmailContent(9)}} >次数限制</Button>
                        <Button type="primary" onClick={() => {selectEmailContent(10)}} >字数</Button>
                    </Space>
                </Form.Item>
                <Form.Item label="Content" name="content" required>
                    <TextArea rows={25}/>
                </Form.Item>
            </Form>
            </Modal>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>反馈详情</Breadcrumb.Item>
            </Breadcrumb>
            <Descriptions title="反馈详情">
                <Descriptions.Item label="User ID">{feedbackDetail.user_id}</Descriptions.Item>
                <Descriptions.Item label="App Name">{feedbackDetail.app_name}</Descriptions.Item>
                <Descriptions.Item label="App Version">{feedbackDetail.app_version}</Descriptions.Item>
                <Descriptions.Item label="Phone Model">{feedbackDetail.phone_model}</Descriptions.Item>
                <Descriptions.Item label="Sys Version">{feedbackDetail.sys_version}</Descriptions.Item>
                <Descriptions.Item label="Connection" >{feedbackDetail.connection} <Space wrap><Button onClick={showModal}>发送邮件</Button></Space></Descriptions.Item>
                <Descriptions.Item label="Network">{feedbackDetail.network}</Descriptions.Item>
                <Descriptions.Item label="content">{feedbackDetail.content}</Descriptions.Item>
                <Descriptions.Item label="Submit At">{feedbackDetail.atime}</Descriptions.Item>
                <Descriptions.Item label="Install At">{feedbackDetail.itime}</Descriptions.Item>
                <Descriptions.Item label="Status">
                    <Space wrap>
                        <Select
                            value={feedbackDetail.status}
                            style={{
                                width: 120,
                            }}
                            disabled={feedbackDetail.status ? true : false}
                            onChange={statusChange}
                            options={[
                                {
                                    value: 0,
                                    label: "未处理"
                                },
                                {
                                    value: 1,
                                    label: "已回复"
                                },
                            ]}
                        />
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="log"><a href={feedbackDetail.log_id} target="_blank" rel="noreferrer"><Tag color="blue">下载</Tag></a></Descriptions.Item>
            </Descriptions>
            <Descriptions title="操作">
                <Descriptions.Item>
                    <Button type="primary" danger onClick={deleteFb}>删除</Button>
                </Descriptions.Item>
            </Descriptions>
            <List size="small" header="图片" dataSource={feedbackDetail.images} renderItem={renderImgOrVideo} />
        </div>
    )
}

export default FeedbackDetail;